/* ----------------------------------------------
 * Generated by Animista on 2024-9-10 16:6:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
 .scale-in-bottom{-webkit-animation:scale-in-bottom .8s cubic-bezier(.25,.46,.45,.94) both;animation:scale-in-bottom .8s cubic-bezier(.25,.46,.45,.94) both}

 @-webkit-keyframes scale-in-bottom{0%{-webkit-transform:scale(0);transform:scale(0);-webkit-transform-origin:50% 100%;transform-origin:50% 100%;opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:50% 100%;transform-origin:50% 100%;opacity:1}}@keyframes scale-in-bottom{0%{-webkit-transform:scale(0);transform:scale(0);-webkit-transform-origin:50% 100%;transform-origin:50% 100%;opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:50% 100%;transform-origin:50% 100%;opacity:1}}

 .tracking-in-expand{animation:tracking-in-expand 1s cubic-bezier(.215,.61,.355,1.000) both}
 @keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}

 .scale-in-right{animation:scale-in-right 1.6s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes scale-in-right{0%{transform:scale(0);transform-origin:100% 50%;opacity:1}100%{transform:scale(1);transform-origin:100% 50%;opacity:1}}

 .scale-in-left{animation:scale-in-left 1.9s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes scale-in-left{0%{transform:scale(0);transform-origin:0 50%;opacity:1}100%{transform:scale(1);transform-origin:0 50%;opacity:1}}

 .slide-in-top{animation:slide-in-top 1s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes slide-in-top{0%{transform:translateY(-1000px);opacity:0}100%{transform:translateY(0);opacity:1}}


 .slide-in-right{animation:slide-in-right 2.5s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes slide-in-right{0%{transform:translateX(1000px);opacity:0}100%{transform:translateX(0);opacity:1}}

 .slide-in-left{animation:slide-in-left 2s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes slide-in-left{0%{transform:translateX(-1000px);opacity:0}100%{transform:translateX(0);opacity:1}}

 .scale-in-ver-bottom{animation:scale-in-ver-bottom 1s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes scale-in-ver-bottom{0%{transform:scaleY(0);transform-origin:0 100%;opacity:1}100%{transform:scaleY(1);transform-origin:0 100%;opacity:1}}

 .scale-in-left{animation:scale-in-left 1s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes scale-in-left{0%{transform:scale(0);transform-origin:0 50%;opacity:1}100%{transform:scale(1);transform-origin:0 50%;opacity:1}}
 .vibrate-1 {
    animation: up-down 2s linear infinite both;
  }
  
  @keyframes up-down {
    0% { transform: translateY(0); }
    20% { transform: translateY(-4px); }
    40% { transform: translateY(4px); }
    60% { transform: translateY(-4px); }
    80% { transform: translateY(4px); }
    100% { transform: translateY(0); }
  }

  .text-shadow-drop-center{animation:text-shadow-drop-center 2s both}
  @keyframes text-shadow-drop-center{0%{text-shadow:0 0 0 transparent}100%{text-shadow:0 0 18px rgba(0,0,0,.35)}}
  
 .focus-in-contract-bck{animation:focus-in-contract-bck 2s cubic-bezier(.25,.46,.45,.94) both}
 @keyframes focus-in-contract-bck{0%{letter-spacing:1em;transform:translateZ(300px);filter:blur(12px);opacity:0}100%{transform:translateZ(12px);filter:blur(0);opacity:1}}
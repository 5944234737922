@tailwind base;
@tailwind components;
@tailwind utilities;
/* Tailwind provides many utilities for dropdowns and side menus. */

.dropdown-menu {
    @apply absolute bg-white shadow-lg mt-2 w-48 hidden;
}

.group:hover .dropdown-menu {
    @apply block;
}

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

.navbar {
    position: sticky;
    top: 0;
    /* width: 100%; */
    background-color: white;
    /* -webkit-box-shadow: 2px 6px 18px 0px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 2px 6px 18px 0px rgba(0, 0, 0, 0.31);
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.31); */
    z-index: 1000;
}

/* Navbar content styling */
.navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 40px;
}

/* Navbar links */
.navbar-content a {
    color: black;
    text-decoration: none;
    padding: 0px 3px;
    font-size: 21px;
    position: relative;
    /* Needed for dropdown positioning */
}

.navbar-content a:hover {
    color: rgba(0, 0, 0, 0.318);
}

.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown menu (hidden by default) */
.dropdown-menu {
    display: none; /* Hidden by default */
    position: absolute;
    background-color: white;
    left:-100%;
    min-width: 380px;
    max-width: 420px;
    /* box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2); */
    padding: 12px; /* Use px instead of % for padding */
    z-index: 100;
    border: none;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    
}

@media (max-width: 576px) { /* Bootstrap's breakpoint for small screens */
    .dropdown-item-custom {
      flex-direction: row;
    }
 
    .dropdown-menu {
        display: none; /* Hidden by default */
        position: absolute;
        background-color: white;
        left:-100%;
        max-width: 200px;
        padding: 12px; /* Use px instead of % for padding */
        z-index: 100;
        border: none;
        display: flex;
        flex-direction: column;
    }
  }

/* Dropdown menu links */
.dropdown-menu a {
    color: black;
    display: block; /* Make links block-level to allow padding and margin */
    padding: 12px 0px;
    text-decoration: none;
    display: flex;
    width: 50%; /* Adjust width to fit 2 columns */
    box-sizing: border-box; 
}
.dropdown-menu a:hover {
    color: rgba(255, 168, 54);
    background-color: white;
}

/* Add hover effect */
.dropdown:hover .dropdown-menu {
    display: flex;
}
.mobile{
    flex-direction: column;
    flex-wrap: wrap;
    left:10%;
    min-width: 300px;
}

.dropdown-menu {
    @apply absolute bg-white shadow-lg mt-2 w-2 hidden;
}

.group:hover .dropdown-menu {
    @apply flex;
}

  